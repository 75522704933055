<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-10">
        <h1>Create child job for: {{ parentJobNumber.job_number }}</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6">
        <label>Short job description</label>
        <input type="text" v-model="description">
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-6">
          <button @click="saveJobNumber()" class="button">Save Child Job</button>
        </div>
      </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'JobNumbersCreateChild',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      description: '',
      parentJobNumber: {},
    };
  },
  methods: {
    getParent() {
      const parentId = this.$route.params.jobNumberId;
      axios.get(`/jobNumbers/getOne/${parentId}.json?token=${this.token}`)
        .then((response) => {
          this.parentJobNumber = response.data.jobNumber;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    saveJobNumber() {
      const postData = {};
      postData.parentJobNumber = this.parentJobNumber;
      postData.description = this.description;
      postData.userid = this.userid;
      axios.post(`/jobNumbers/createChild.json?token=${this.token}`, postData)
        .then(() => {
          this.$router.push('/job-numbers/index');
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    this.getParent();
  },
};
</script>
